import { gql } from "@apollo/client/core";
import { useLazyQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { reactive, ref } from "vue";
import moment from "moment";
import {
  DBDateLong,
  formatDate,
  getSign,
  numberSeparators,
} from "@/graphql/utils/utils";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import {
  ComparativeRapportInput,
  QueryStockMovementsArgs,
  StockMovementsOutput,
} from "../types";
import { useI18n } from "vue-i18n";
import { printRapport } from "@/components/rapport/printer";

type StockMovementsRapportData = {
  stockMovements: StockMovementsOutput[];
};
const STOCK_MOVEMENTS = gql`
  query StockMovements($input: ComparativeRapportInput!) {
    stockMovements(input: $input) {
      id
      createdAt
      motif
      movement
      balance
      productId
      product
      categoryId
      category
    }
  }
`;

const FILTER = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  productId: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
  },
  categoryId: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
  },
  movement: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  balance: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export const useStockMovementsRapport = () => {
  const filters = ref({ ...FILTER });
  const date = new Date();
  const { t, d, n, tm } = useI18n();
  function formatNumber(value: number) {
    return n(
      Number((value || 0).toFixed(activeActivity.value.decimalNumber)),
      "decimal",
      numberSeparators[activeActivity.value.separator]
    );
  }
  const input = reactive<ComparativeRapportInput>({
    activityId: activeActivity.value.id,
    startAt: moment(date).add(-1, "days").toDate(),
    endAt: date,
    period: null,
  });
  const { result, loading, load } = useLazyQuery<
    StockMovementsRapportData,
    QueryStockMovementsArgs
  >(STOCK_MOVEMENTS);
  const resume = reactive({
    products: [],
    categories: [],
  });
  const movements = useResult<
    StockMovementsRapportData,
    StockMovementsOutput[],
    StockMovementsOutput[]
  >(result, [], (res) => {
    const rows: StockMovementsOutput[] = [];
    resume.products = [];
    resume.categories = [];
    res.stockMovements.forEach((item) => {
      rows.push({
        ...item,
        category:
          tm("rapport.categories")[item.categoryId - 1] || item.category,
        createdAt: new Date(item.createdAt),
      });
      if (resume.products.findIndex((p) => p.id == item.productId) < 0) {
        resume.products.push({
          id: item.productId,
          name: item.product,
        });
      }
      if (resume.categories.findIndex((c) => c.id == item.categoryId) < 0) {
        resume.categories.push({
          id: item.categoryId,
          label: item.category,
        });
      }
    });
    return rows;
  });
  function initData() {
    void load(
      STOCK_MOVEMENTS,
      {
        input: { ...input },
      },
      { fetchPolicy: "no-cache" }
    );
  }
  function refresh() {
    Object.assign(filters.value, FILTER);
  }

  function print() {
    printRapport(`<table>
      <thead>
        <tr>
          <th class="p-text-uppercase" colspan="5">
            <h2 class="p-pt-3">${t("rapport.tab4")}</h2>
          </th>
        </tr>
        <tr>
          <th class="p-text-left" style="border-right: unset" colspan="1">
            ${t("rapport.period")}
          </th>
          <th colspan="2" class="p-no-border">
            ${t("rapport.from")}
            ${d(input.startAt, "long")}
          </th>
          <th class="p-text-right" style="border-left: unset" colspan="2">
            ${t("rapport.to")}
            ${d(input.endAt, "long")}
          </th>
        </tr>
        <tr>
          <th class="p-text-left">${t("date")}</th>
          <th>${t("product.product")}</th>
          <th>${t("product.category")}</th>
          <th>${t("stock.movement")}</th>
          <th class="p-text-right">${t("rapport.balance")}</th>
        </tr>
      </thead>
      <tbody>
        ${movements.value
          .map(
            (data) => `<tr>
          <td class="p-text-left">
            ${d(data.createdAt, "long")}
          </td>
          <td>${data.product}</td>
          <td>${data.category}</td>
          <td>
            ${getSign(data.movement, data.motif)}
            ${formatNumber(data.movement)}
          </td>
          <td class="p-text-right">
            ${data.balance}
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5">
            ${t("rapport.movementCount")} :
            ${formatNumber(movements.value.length)}
          </th>
        </tr>
      </tfoot>
    </table>`);
  }
  return {
    loading,
    resume,
    movements,
    input,
    initData,
    refresh,
    filters,
    print,
  };
};
