
import { defineComponent } from "vue";
import CategoryMultiSelect from "@/components/category/CategoryMultiSelect.vue";
import ProductMultiSelect from "@/components/product/ProductMultiSelect.vue";
import { useStockMovementsRapport } from "@/graphql/rapport/stock.movements.rapport";
import RapportFilter from "./RapportFilter.vue";
import { CONSTANTS, getSign } from "@/graphql/utils/utils";

export default defineComponent({
  name: "StockMovementsRapport",
  components: {
    CategoryMultiSelect,
    ProductMultiSelect,
    RapportFilter,
  },
  setup() {
    return {
      ...useStockMovementsRapport(),
      getSign,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
    };
  },
  created() {
    this.initData();
  },
});
